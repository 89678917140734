import React from 'react'
import { graphql } from 'gatsby'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import AltSection from '../components/columnAlternatingStyled'

const SolutionsTemplate = ({ data }) => {
  const post = data.markdownRemark

  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          foreimage={null}
          forealt={post.frontmatter.imageAltText}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}          
          type="root"
          gradientClass="mask d-flex justify-content-center align-items-center gradient"
        />
      </div>

      <section className="bg-white">
        <MDBContainer>
          <MDBRow>
            <MDBCol lg={post.frontmatter.headline != null ? '8' : '12'}>
              <div className="font-w-400 text-xs-extra-large text-opening-para"> {post.frontmatter.openingpara} </div> 
              <div className="richtext divlink mt-3" dangerouslySetInnerHTML={{ __html: post.html }} /> 
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      {post.frontmatter.section ? (
        <section className="bg-light-blue">
          <MDBContainer>
            <div className="about-overview">
              <h2 className="font-alt font-w-700 text-center letter-spacing-1 title-xs-medium title-large pb-5 pb-md-6"> Capabilities </h2> 
              {post.frontmatter.section.map((sections, index) => {
                return (
                  <AltSection
                    key={index}
                    title={sections.title}
                    subtitle={null}
                    description={sections.description}
                    image={sections.image.childImageSharp.gatsbyImageData}
                    imageAltText={sections.alttext}
                    placement={sections.placement}
                    titleClass="font-alt font-w-400 letter-spacing-1 mb-3 pb-4 title-xs-medium title-medium text-very-dark-blue"
                    link={sections.btnlink}
                    colour={sections.btncolour}
                  />
                )
              })}
            </div>
          </MDBContainer>
        </section>
      ) : (
        ''
      )}
    </Layout>
  )
}
export default SolutionsTemplate

export function Head({data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.image.childImageSharp?.gatsbyImageData?.images?.fallback?.src}          
      canonical={location.pathname}                  
    />
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(
      fields: { slug: { eq: $slug } }
      frontmatter: { template: { eq: "solutions" } }
    ) {
      html
      frontmatter {
        meta {
          title
          description 
        }
        template
        title
        subtitle
        ctatext
        ctaslug         
        description
        openingpara
        image {
          childImageSharp {
            gatsbyImageData(width: 1980, quality: 90) 
          }
        }
      suite
        headline {
          title
          icon
          description
          linktext
          link
          resource {
            publicURL
          }
        }
        section {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(width: 960, quality: 90)
            }
          }
          placement
          alttext
          btnlink
          btncolour
        }
      }
    }
  }
`
